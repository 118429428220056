import { Button } from '@capturi/ui-components'
import {
  BoxProps,
  Flex,
  FlexProps,
  Icon,
  StackProps,
  Text,
  TextProps,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import isEmpty from 'lodash/isEmpty'
import React, { useContext } from 'react'
import { MdFlag } from 'react-icons/md'

import EditableWidgetContext from '../../contexts/EditableWidgetContext'
import { WidgetGoal } from '../../types'
import { formatRange, isWithinRange } from '../../utils/referenceRange'

export const Container: React.FC<StackProps> = (props) => {
  const [isLargerThanHD, isDisplayingInFullscreen] = useMediaQuery([
    '(min-width: 1920px)',
    '(display-mode: fullscreen)',
  ])
  let fontSizes = ['md', null, null, 'lg', 'xl']
  if (isLargerThanHD && isDisplayingInFullscreen) {
    fontSizes = ['md', null, 'lg', 'xl', '2xl', '3xl']
  }
  return (
    <Flex
      width="100%"
      height="100%"
      direction="column"
      fontSize={fontSizes}
      {...props}
    />
  )
}

export const Title: React.FC<{ children?: React.ReactNode }> = (props) => {
  const ctx = useContext(EditableWidgetContext)
  if (isEmpty(props.children)) return null

  const baseProps = {
    fontSize: '1em',
    fontWeight: 'medium',
    noOfLines: 1,
    flex: '0 0 auto',
    color: 'inherit',
  }

  return (
    <Tooltip label={props.children}>
      {typeof ctx?.onEditWidget === 'function' ? (
        <Button
          {...baseProps}
          display="block"
          wordBreak="break-all"
          variant="unstyled"
          onClick={() => ctx?.onEditWidget?.()}
          alignSelf="flex-start"
          minW="auto"
          maxW="100%"
          height="auto"
          textAlign="left"
          _hover={{ textDecoration: 'underline' }}
          {...props}
        />
      ) : (
        <Text {...baseProps} wordBreak="break-all" {...props} />
      )}
    </Tooltip>
  )
}

export const Description: React.FC<TextProps> = (props) => {
  if (isEmpty(props.children)) return null
  return (
    <Tooltip label={props.children}>
      <Text
        fontSize="0.75em"
        color="gray.600"
        noOfLines={1}
        wordBreak="break-all"
        flex="0 0 auto"
        {...props}
      />
    </Tooltip>
  )
}

export const Content: React.FC<FlexProps> = (props) => {
  return (
    <Flex width="100%" height="100%" flex={1} overflow="hidden" {...props} />
  )
}

export const Footer: React.FC<FlexProps> = (props) => {
  return (
    <Flex
      align="center"
      justify="space-between"
      wrap="nowrap"
      lineHeight={1.1}
      mt={1}
      fontSize="0.875em"
      {...props}
    />
  )
}

export const SubText: React.FC<TextProps> = (props) => {
  if (isEmpty(props.children)) return null
  return (
    <Text
      alignSelf="flex-end"
      fontSize="0.65em"
      noOfLines={1}
      wordBreak="break-all"
      ml="auto"
      textAlign="right"
      {...props}
    />
  )
}

const defaultFormatFn = (val: number): string =>
  i18n.number(val, {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  })

type GoalProps = {
  unit?: string
  formatValue?: (val: number) => string
  value?: number
} & WidgetGoal &
  BoxProps

export const Goal: React.FC<GoalProps> = ({
  min,
  max,
  value,
  unit,
  formatValue = defaultFormatFn,
  ...restProps
}) => {
  if (min == null && max == null) {
    return null
  }
  const isValueWithinRange = isWithinRange(value, min, max)
  return (
    <Flex align="center" {...restProps}>
      <Icon
        as={MdFlag}
        mr={1}
        color={isValueWithinRange ? 'segmentQuaternary' : 'text'}
      />
      <Text noOfLines={1} wordBreak="break-all">
        <Text as="span">{formatRange(min, max, formatValue)}</Text>
        {unit != null && (
          <Text as="span" pl="2px">
            {unit}
          </Text>
        )}
      </Text>
    </Flex>
  )
}
